import {
  Image,
  Stack,
  Link,
  Button,
  VStack,
  Text,
  Grid,
  GridItem,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Subscribe from "../components/Subscribe";
import HOME_BANNER from "../assets/home-banner.jpg";
import { Check } from "src/components/svgs";
import ChainGroup from "src/components/ChainGroup";
import Statistics from "src/components/Statistics";

export interface Post {
  title: string;
  link: string;
  pubDate: string;
  guid: string;
  thumbnail: string;
  description: string;
  author: string;
}

export default function Home() {
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    fetch("https://api.omnity.network/api/posts")
      .then((data) => data.json())
      .then((data) => {
        setPosts(data.posts.slice(0, 3));
      })
      .catch(console.log);
  }, []);

  const px = { base: 4, md: 16 };

  return (
    <Stack w="100%">
      <Image
        src={HOME_BANNER}
        alt="banner"
        objectFit="cover"
        w="100%"
        h="100vh"
        pos="absolute"
      />
      <VStack
        h="100vh"
        mt={120}
        px={px}
        gap={8}
        py={{ base: 8, md: 16 }}
        pos="relative"
      >
        <VStack
          w="100%"
          zIndex={10}
          alignItems="center"
          justifyContent="center"
          gap={8}
          pt={{ base: 12, md: 24 }}
        >
          <VStack
            gap={2}
            fontSize={{ base: 50, md: 80 }}
            lineHeight={1.2}
            fontWeight={700}
          >
            <Text>Omnity Ignites BTCFi</Text>
          </VStack>

          <Text fontSize={20} lineHeight={1.25} maxW={700}>
            Unleash Bitcoin's full potential with Omnity Hub and REE, redefining
            interoperability and programmability of Bitcoin.
          </Text>

          <Stack
            flexDir={{ base: "column", md: "row" }}
            gap={8}
            justifyContent="center"
          >
            <Button
              background="linear-gradient(90deg, #5A85E9 -16.11%, #B120BD 105.23%)"
              borderRadius="full"
              px={8}
              py={6}
              fontSize={20}
              as={Link}
              href="/hub"
              color="white"
              _hover={{
                bg: "#B120BD",
                color: "white",
                textDecoration: "none",
                boxShadow: "0 0 0 0.2rem rgba(213,55,144,.5)",
              }}
            >
              Hub
            </Button>
            <Button
              background="linear-gradient(90deg, #D53790 0%, #F29032 100%)"
              borderRadius="full"
              px={8}
              py={6}
              as={Link}
              href="/ree"
              fontSize={20}
              color="white"
              _hover={{
                bg: "#F29032",
                color: "white",
                textDecoration: "none",
                boxShadow: "0 0 0 0.2rem rgba(213,55,144,.5)",
              }}
            >
              REE
            </Button>
          </Stack>
        </VStack>
      </VStack>

      <Stack
        flexDirection="column"
        px={px}
        py={24}
        pt={0}
        gap={24}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={48} fontWeight={600}>
          Tackling BTCFi's Biggest Hurdles
        </Text>
        <Stack
          flexDir={{ base: "column", md: "row" }}
          justifyContent="center"
          gap={12}
        >
          <VStack
            alignItems="flex-start"
            background="linear-gradient(90deg, #354D9D 0%, #661E8B 101.98%)"
            p={8}
            borderRadius={20}
            gap={4}
            maxW={600}
          >
            <Text textAlign="left" fontSize={26} fontWeight={600}>
              Omnity Hub: Fully On-Chain Bitcoin Interoperability Stack
            </Text>
            <Text textAlign="left">
              Omnity Hub is a fully on-chain, trust-minimized solution that
              connects Bitcoin with other L1s and L2s, supporting BTC, Runes and
              BRC20.
            </Text>
            <VStack alignItems="flex-start" gap={4}>
              {[
                "Fully On-Chain Verification and Relaying",
                "Minimized Cost and Latency",
                "Mature and Reliable",
                "Persistent Token Fungibility",
              ].map((text) => (
                <HStack key={text}>
                  <Check size={24} />
                  <Text fontSize={{ base: 14, md: 16 }}>{text}</Text>
                </HStack>
              ))}
            </VStack>
          </VStack>

          <VStack
            alignItems="flex-start"
            background="linear-gradient(90deg, #823AB4 0%, #844D37 101.98%)"
            p={8}
            borderRadius={20}
            gap={4}
            maxW={600}
          >
            <VStack alignItems="flex-start" gap={0}>
              <Text textAlign="left" fontSize={26} fontWeight={600}>
                {"REE: Bitcoin-Native Execution Layer"}
              </Text>
              <Text textAlign="left" fontSize={26} fontWeight={600}>
                {"\u00a0"}
              </Text>
            </VStack>

            <Text textAlign="left">
              Runes Exchange Environment (REE) is a decentralized execution
              layer that enables composable smart contracts for Bitcoin L1
              without asset bridging.
            </Text>
            <VStack alignItems="flex-start" gap={4}>
              {[
                "Turing-Complete Programmability",
                "Bridgeless Execution",
                "Composable Smart Contracts",
                "Open Platform",
              ].map((text) => (
                <HStack key={text}>
                  <Check size={24} />
                  <Text fontSize={{ base: 14, md: 16 }}>{text}</Text>
                </HStack>
              ))}
            </VStack>
          </VStack>
        </Stack>
      </Stack>

      <Statistics />

      <ChainGroup />

      <Stack
        flexDirection="column"
        px={px}
        py={24}
        gap={12}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={48} fontWeight={600}>
          Powered by{" "}
          <Link href="https://internetcomputer.org/chainfusion" target="_blank">
            <Text
              bgGradient="linear(to-l, #7928CA, #FF0080)"
              bgClip="text"
              display="inline-block"
              fontWeight={600}
            >
              ICP Chain Fusion
            </Text>
          </Link>
        </Text>
        <Text fontSize={24} fontWeight={400} maxW={1100}>
          Omnity leverages ICP Chain-Key Cryptography, Bitcoin native
          integration, HTTPS Outcalls, and the reverse gas model to enable fully
          on-chain interoperability and Bitcoin-native programmability
        </Text>
      </Stack>

      <Stack
        flexDirection="column"
        px={px}
        py={24}
        gap={12}
        justifyContent="center"
        alignItems="center"
      >
        <Text fontSize={48} fontWeight={600}>
          Backed By
        </Text>
        <Grid
          templateColumns={{
            base: `repeat(3, 1fr)`,
            md: `repeat(7, 1fr)`,
          }}
          py={4}
          gap={4}
          w="100%"
          justifyContent={"center"}
        >
          {new Array(21).fill(0).map((_, i) => {
            return (
              <Image
                key={i}
                src={`/backers/Process ${i + 1}.png`}
                width="100%"
                objectFit={"contain"}
                alt={""}
              />
            );
          })}
        </Grid>
      </Stack>

      <VStack id="blog" px={px} py={24} gap={8}>
        <Text fontSize={48} lineHeight="60px" fontWeight={600}>
          Latest Articles
        </Text>

        <Grid
          w="100%"
          justifyContent={"center"}
          templateColumns={{
            base: `repeat(1, 1fr)`,
            md: `repeat(3, 1fr)`,
          }}
          gap={8}
          mt={8}
        >
          {posts.slice(0, 6).map((p) => {
            return (
              <GridItem key={p.guid} w="100%">
                <Link
                  href={p.link}
                  target="_blank"
                  transition="transform 0.2s"
                  transitionTimingFunction="ease-in-out"
                  _hover={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  role="group"
                >
                  <VStack h="100%">
                    <Image
                      src={p.thumbnail}
                      w="100%"
                      aspectRatio={"16/9"}
                      objectFit={"cover"}
                      borderRadius={8}
                      _hover={{
                        transform: "scale(1.01)",
                        boxShadow: "0 0 10px white",
                      }}
                    />
                    <VStack
                      flex={1}
                      w="100%"
                      gap={0}
                      alignItems={"flex-start"}
                      justifyContent={"space-between"}
                    >
                      <Text fontWeight={600} textAlign={"left"}>
                        {p.title}
                      </Text>
                      <HStack w="100%" justifyContent={"space-between"}>
                        <Text color="gray" fontSize={12}>
                          {p.author}
                        </Text>
                        <Text color="gray" fontSize={12}>
                          {dayjs(p.pubDate).format("YYYY-MM-DD")}
                        </Text>
                      </HStack>
                    </VStack>
                  </VStack>
                </Link>
              </GridItem>
            );
          })}
        </Grid>
      </VStack>

      <VStack px={px} py={{ base: 12, md: 32 }} gap={6}>
        <VStack
          fontSize={{ base: 32, md: 48 }}
          lineHeight="61px"
          fontWeight={600}
        >
          <Text>Stay Updated with Omnity</Text>
        </VStack>
        <Subscribe />
      </VStack>
    </Stack>
  );
}
